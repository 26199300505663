import './App.css';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <h3>Música <strong>Plena</strong></h3>
        <p>Estamos em construção, em breve<br />voltaremos com <strong>novidades!</strong></p>
      </header>
    </div>
  );
}

export default App;
